<template>
    <b-card-group deck id="grupo" fluid >
        <b-row>   
          <p class="display-4  text-center mt-3 text-white" >Quais as Vantagens da ActShare</p>
            <b-col v-for="vant in vantagens" v-bind:key="vant.id" >         

            <b-card
                v-bind:title="vant.titulo"
                tag="article"
                style="max-width: 20rem;"
                text-variant="white"
                bg-variant="primary"
                align="left"
                class="m-4"
            >
            <div style="font-size: 4rem;">
                <b-icon v-bind:icon="vant.icone" class="border rounded p-2"></b-icon>
            </div>    
             <b-card-text>
             {{vant.title}}
             </b-card-text>
             <b-card-text>
             {{vant.descricao}}
             </b-card-text>

            </b-card>

            </b-col>

       </b-row>
            
    </b-card-group>


</template>

<script>
export default {
  data() {
        return {
            vantagens: [],
            loading:false,
            erro:'',
            show:false
        }

    },
 methods: {
       getVantagens() {
      
      this.loading = true;
      const url = process.env.VUE_APP_BACKEND + "ajax_get_vantagens.php";
      fetch(url)
        .then((response) => {
          if (response.ok) {
            return response.json();
          }
        })
        .then((data) => {
          this.vantagens = data;
          this.loading = false;
        })
        .catch(function (error) {
          this.error = error;
        });
      },

    },
    mounted() {

        this.getVantagens();

    }


}
</script>

<style scoped>
  #grupo {
   background-image: url('../assets/backgroundactshare.png');
  }
.bg-primary {
    --bs-bg-opacity: 1;
    background-color: transparent !important;
}

</style>