import { render, staticRenderFns } from "./ActCupom.vue?vue&type=template&id=964a8692&scoped=true&"
import script from "./ActCupom.vue?vue&type=script&lang=js&"
export * from "./ActCupom.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "964a8692",
  null
  
)

export default component.exports