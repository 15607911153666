import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../components/ActHome'
import Parceiro from '../components/ActParceiro'
import Pedidos from '../components/ActPedidosGrid'
import Login from '../components/act-login'
import Conta from '../components/ActConta'
import Certificado from '../components/ActCertificado'
Vue.use(VueRouter)

export default new VueRouter({
    routes: [
        {
            path: '/',
            name: 'Home',
            component: Home
        },
        {
            path: '/parceiro',
            name: 'Parceiro',
            component: Parceiro
        },
        {
            path: '/pedidos',
            name: 'Pedidos',
            component: Pedidos
        },
        {
            path: '/login',
            name: 'Login',
            component: Login
        },
        {
            path: '/conta',
            name: 'Conta',
            component: Conta

        },
        {
            path: '/certificado',
            name: 'Certificado',
            component: Certificado

        }


]
})