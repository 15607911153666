<template>
    <b-row style=" background-color: #002046" class="pb-3">
      
       <b-col sm="6" md="2">
        <img
              id="logofooter"
              src="../assets/logo.png"
              alt="Actshare" 
              class="img-fluid"
            />
       

           
       </b-col>

       <b-col sm="12" md="2">
         <img
              id="logocandrevafooter"
              src="../assets/Candreva.png"
              alt="Actshare" 
              class="img-fluid mx-auto d-block"
             
            /> 
       </b-col>

       <b-col sm="6" md="3">
            <b-card title="Endereço"  bg-variant="primary" border-variant="primary" text-variant="white" class="mt-2 border-0">
                <b-card-text>
                <small> {{empresa.end}} <br>{{empresa.end2}} </small>
                </b-card-text>
                <b-card-text>
               <small> {{empresa.cnpj}}</small>
                </b-card-text>
            </b-card>
       </b-col>
       <b-col sm="6" md="2">
          <b-card title="Paginas"  bg-variant="primary" border-variant="light" text-variant="white" class="mt-2 border-0">
          <b-card-text>


            <b-nav vertical class="w-30">
                <b-nav-item :to="{ name: 'Home'}">Home</b-nav-item>
                 <b-nav-item href="https://lgpdeasy.com.br/sistema/app_Login/" target="_blank">Plataforma LGPD</b-nav-item>
                <b-nav-item :to="{ name: 'Parceiro'}">Parceiros</b-nav-item>
                <b-nav-item :to="{ name: 'Certificado'}">Certificado</b-nav-item>
<b-nav-item href="#" v-on:click.self.prevent="$bvModal.show('modalpolitica_2')">Politica de Privacidade</b-nav-item>

        <b-nav-item-dropdown>
          <!-- Using 'button-content' slot -->
          <template #button-content>
            <em><b-icon icon="person-circle"></b-icon></em>
          </template>
          <b-dropdown-item v-if="!logado" :to="{ name: 'Login'}"
          v-b-tooltip.hover.html="tipData" 
          >Entrar</b-dropdown-item>
          <b-dropdown-item v-if="!logado" :to="{ name: 'Conta'}"
          v-b-tooltip.hover.html="tipData2" 
          >Criar Conta</b-dropdown-item>
          <b-dropdown-item v-if="logado" @click="sair()">Sair</b-dropdown-item>
        </b-nav-item-dropdown>

            </b-nav>

          </b-card-text>
          </b-card>  
       </b-col> 

       <b-col sm="6" md="2">
            <b-card title="Fale Conosco"  bg-variant="primary" border-variant="light" text-variant="white" class="mt-2 border-0">
                <b-card-text>
                <small> {{empresa.email}} </small>
                </b-card-text>
                <b-card-text>
                <small>Tel: {{empresa.tel}} </small>
                </b-card-text>
            </b-card>
       </b-col>
  <act-politica-privacidade
    :id='nomemodal'
    :texto='politicatexto'
    >
</act-politica-privacidade>
    </b-row>
</template>

<script>
const axios = require('axios')
import ActPoliticaPrivacidade from "./ActPoliticaPrivacidade.vue"
export default {
   components: {
     ActPoliticaPrivacidade
   },
   data() {
        return {
            empresa: '',
            loading:false,
            erro:'',
            show:false,
            nomemodal:"2",
      tipData: { title: '<em>Para ver Pedidos e ter uma experiência personalizada acesse sua conta</em>' },
      tipData2: { title: '<em>É Cliente novo ? Cria sua conta para ver Pedidos e ter uma experiência personalizada</em>' },

        }

    },
    computed: {
      politicatexto() {
        return this.$store.getters.getPoliticaTexto
      },
      logado() {
        return this.$store.getters.getLogado
      }
    },
 methods: {


       getEmpresa() {
         this.loading = true;
         const url = process.env.VUE_APP_BACKEND + "ajax_empresa_footer.php";
         axios.get(url).then((response) => {
             this.empresa = response.data;
             this.loading = false;
             console.log('axios executou ajax_empresa_footer ok')
         }).catch(function (error) {
            // handle error
            console.log(error);
         })
   
      },

    },
    mounted() {

        this.getEmpresa();

    }

 
}
</script>

<style>

#logofooter {
 
  margin-top: 30px;
 
  
}
#logocandrevafooter {
  margin-top :40px; 
  
 
}
@media screen and (min-width: 768px) {
  #logocandrevafooter {
    margin-top :40px;
    height: 40%;
    width: 60%; 
  }
 
}
  
.bg-primary {
    --bs-bg-opacity: 1;
    background-color: transparent !important;
}

</style>