import { render, staticRenderFns } from "./ActDescProg.vue?vue&type=template&id=4774e796&scoped=true&"
import script from "./ActDescProg.vue?vue&type=script&lang=js&"
export * from "./ActDescProg.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4774e796",
  null
  
)

export default component.exports