<template>
     <b-card-group deck >
        <b-row>   
          <p class="display-4  text-center mt-3 text-primary" >Quem Somos</p>
            <b-col cols="12" md="auto" v-for="soc in socios" v-bind:key="soc.id" >         

            <b-card
               
                v-bind:img-src="soc.foto"
                tag="article"
                img-alt="Image"
                img-top
                style="max-width: 18rem;"
                text-variant="primary"
                align="center"
               
                class="m-2"
            >
             <b-card-text class="text-primary">
               <h3 v-for="nome in soc.titulo.split(' ')" :key="nome">
               {{nome}}
               </h3>
             </b-card-text>
             <b-card-text class="text-info">
             {{soc.tipo}}
             </b-card-text>
              <b-card-text class="text-black" v-html="soc.truncado">
             {{ soc.truncado }} 
             
             </b-card-text>

<act-modal-texto 
    :texto="soc.descricao"
    :titulo="soc.titulo"
    :id="soc.id"
    
>
   
</act-modal-texto>    

            </b-card>

            </b-col>

       </b-row>
            
    </b-card-group>
</template>

<script>
import ActModalTexto from './ActModalTexto.vue' 
export default {
    components: {
       ActModalTexto
    },
    data() {
   
        return {
            socios: [],
            loading:false,
            erro:'',
            show:false
        }

    },
    
 methods: {

       vermais(id)  {
            alert("id "+id)
       },

       getSocios() {
      
      this.loading = true;
      const url = process.env.VUE_APP_BACKEND + "ajax_socios.php";
      fetch(url)
        .then((response) => {
          if (response.ok) {
            return response.json();
          }
        })
        .then((data) => {
          this.socios = data;
          this.loading = false;
        })
        .catch(function (error) {
          this.error = error;
        });
      },

    },
    mounted() {

        this.getSocios();

    }

 
}
</script>


<style scoped>
.btn-primary {
  color:#fff;
  background-color: #002046;
  border-color: #002046;
}
.text-primary {
    --bs-text-opacity: 1;
    color: rgba(0,32,70, var(--bs-text-opacity)) !important;
}


</style>