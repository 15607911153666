<template>
<div class="container-fluid">
<div class="row">
 <div class="col-md-4"></div>
 <div class="col-md-4">

  <b-card class="mt-3 mb-2"
         header="Dados do Parceiro" 
        header-bg-variant="primary"
        header-text-variant="white"
    >
    
     <b-alert show variant="success">{{txtparceiro}} </b-alert>
    <form class="form was-validated" @submit="onSubmit">
      <div class="form-row">
        <act-rotulo nome="Nome" col="col-md-12">
          <input
            type="text"
            class="form-control"
            v-model="cliente.nome"
            required
          />
        </act-rotulo>
        <act-rotulo nome="Email" col="col-md-12">
          <input
            type="email"
            class="form-control"
            v-model="cliente.email"
            required
          />
        </act-rotulo>
        
      </div>

      <div class="form-row">
        
        <act-rotulo nome="Cnpj" col="col-md-6">
          <input
            v-maska="'##.###.###/####-##'"
            v-bind:class="[classeInput, cnpjClass]"
            v-model="cliente.cnpj"
            @change="altcnpj"
            
          />
          <div class="invalid-feedback">Por favor, informe um cnpj válido.</div>
        </act-rotulo>
        <act-rotulo
          nome="Razão Social"
          col="col-md-12"
        >
          <input
            type="text"
            class="form-control"
            v-model="cliente.razao"
            required
          />
        </act-rotulo>
        <act-rotulo nome="CEP" col="col-md-6">
          <input
            v-maska="'#####-###'"
            class="form-control"
            v-model="cliente.cep"
            @change="pegaEndereco"
            required
          />
        </act-rotulo>
      </div>
      <div class="form-row">
        <act-rotulo nome="Endereco" col="col-md-12">
          <input
            type="text"
            class="form-control"
            v-model="cliente.endereco"
            required
          />
        </act-rotulo>
        <act-rotulo nome="Número" col="col-md-4">
          <input type="text" class="form-control" v-model="cliente.endnum" />
        </act-rotulo>
        <act-rotulo nome="Complemento" col="col-md-4">
          <input type="text" class="form-control" v-model="cliente.compl" />
        </act-rotulo>
        <act-rotulo nome="Bairro" col="col-md-12">
          <input
            type="text"
            class="form-control"
            v-model="cliente.bairro"
            required
          />
        </act-rotulo>
      </div>
      <div class="form-row">
        <act-rotulo nome="Cidade" col="col-md-12">
          <input
            type="text"
            class="form-control"
            v-model="cliente.cidade"
            required
          />
        </act-rotulo>
        <act-rotulo nome="Estado" col="col-md-4">
          <input
            type="text"
            class="form-control"
            v-model="cliente.uf"
            required
          />
        </act-rotulo>
        <act-rotulo nome="Tel Comercial" col="col-md-6">
          <input
            v-maska="['(##)####-####', '(##)#####-####']"
            class="form-control"
            v-model="cliente.telcom"
          />
        </act-rotulo>
        <act-rotulo nome="Celular" col="col-md-6">
          <input
            v-maska="'(##)#####-####'"
            class="form-control"
            v-model="cliente.celular"
          />
        </act-rotulo>
      </div>
      <br>
      <b-button type="submit" variant="primary">Enviar</b-button>
      <b-alert class='mt-3' v-bind:show="temmsg" v-bind:variant="classemsg"> {{mensagem}}</b-alert>
    </form>
  </b-card>
  </div>
  <div class="col-md-4"></div>    
</div>  
</div>  
</template>

<script>

import ActRotulo from "./ActRotulo.vue"
export default {
  components: {
     ActRotulo
  }, 
 
  data() {
    return {
      cliente: {
        nome: "",
        cpf: "",
        cnpj: "",
        razao: "",
        email: "",
        endereco: "",
        endnum: "",
        compl: "",
        bairro: "",
        cidade: "",
        uf: "",
        cep: "",
        telcom: "",
        celular: "",
      },
      cpfClass: "",
      cnpjClass: "",
      classeInput: "form-control",
      loading: false,
      error: "",
      texto:""
     
    }
  },
  computed: {
        txtparceiro() {
            
            return this.$store.getters.getTxtParceiro
        },
        enviouparceiro() {
          return this.$store.getters.getEnviouParceiro
        },
        mensagem() {
            return this.$store.getters.getMsgParceiro
        },
        temmsg() {
            let aux = this.mensagem
            if (aux.length > 0) {
                return true
            } else {
                return false
            }
        },
        classemsg() {
            if (this.enviouparceiro) {
                return "success"
            } else {
               return "danger"   
            }
        }
  },
  methods: {
    onSubmit(event) {
       
        event.preventDefault()
        this.$store.dispatch('enviarparceiro',this.cliente)
            
    }, 
    cpfValido(cpf) {
      cpf = this.replaceAll(cpf, ".", "");
      cpf = this.replaceAll(cpf, "-", "");
      var numeros, digitos, soma, i, resultado, digitos_iguais;
      digitos_iguais = 1;
      if (cpf.length < 11) return false;
      for (i = 0; i < cpf.length - 1; i++)
        if (cpf.charAt(i) != cpf.charAt(i + 1)) {
          digitos_iguais = 0;
          break;
        }
      if (!digitos_iguais) {
        numeros = cpf.substring(0, 9);
        digitos = cpf.substring(9);
        soma = 0;
        for (i = 10; i > 1; i--) soma += numeros.charAt(10 - i) * i;
        resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
        if (resultado != digitos.charAt(0)) return false;
        numeros = cpf.substring(0, 10);
        soma = 0;
        for (i = 11; i > 1; i--) soma += numeros.charAt(11 - i) * i;
        resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
        if (resultado != digitos.charAt(1)) return false;
        return true;
      } else return false;
    },

    replaceAll(str, de, para) {
      let pos = str.indexOf(de);
      while (pos > -1) {
        str = str.replace(de, para);
        pos = str.indexOf(de);
      }
      return str;
    },

    validarCNPJ(cnpj) {
      cnpj = cnpj.replace(/[^\d]+/g, "");

      if (cnpj == "") return false;

      if (cnpj.length != 14) return false;

      // Elimina CNPJs invalidos conhecidos
      if (
        cnpj == "00000000000000" ||
        cnpj == "11111111111111" ||
        cnpj == "22222222222222" ||
        cnpj == "33333333333333" ||
        cnpj == "44444444444444" ||
        cnpj == "55555555555555" ||
        cnpj == "66666666666666" ||
        cnpj == "77777777777777" ||
        cnpj == "88888888888888" ||
        cnpj == "99999999999999"
      )
        return false;

      // Valida DVs
      let tamanho = cnpj.length - 2;
      let numeros = cnpj.substring(0, tamanho);
      let digitos = cnpj.substring(tamanho);
      let soma = 0;
      let pos = tamanho - 7;

      for (let i = tamanho; i >= 1; i--) {
        soma += numeros.charAt(tamanho - i) * pos--;
        if (pos < 2) pos = 9;
      }
      let resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
      if (resultado != digitos.charAt(0)) return false;

      tamanho = tamanho + 1;
      numeros = cnpj.substring(0, tamanho);
      soma = 0;
      pos = tamanho - 7;
      for (let i = tamanho; i >= 1; i--) {
        soma += numeros.charAt(tamanho - i) * pos--;
        if (pos < 2) pos = 9;
      }
      resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
      if (resultado != digitos.charAt(1)) return false;

      return true;
    },

    altcpf() {
     
      if (this.cpfValido(this.cliente.cpf)) {
        this.cpfClass = "is-valid";
      } else {
        this.cpfClass = "is-invalid";
      }
    },
    altcnpj() {
      if (this.validarCNPJ(this.cliente.cnpj)) {
        this.cnpjClass = "is-valid";
      } else {
        this.cnpjClass = "is-invalid";
      }
    },

    pegaEndereco() {
      fetch("https://viacep.com.br/ws/" + this.cliente.cep + "/json")
        .then((response) => {
         
          if (response.ok) {
            return response.json();
          }
        })
        .then((data) => {
          this.cliente.endereco = data.logradouro;
          this.cliente.bairro = data.bairro;
          this.cliente.cidade = data.localidade;
          this.cliente.uf = data.uf;
          this.loading = false;
        })
        .catch(function (error) {
          console.log(error);
          this.error = error;
        });
    },
  },
};
</script>

<style scoped>

.bg-primary {
    --bs-bg-opacity: 1;
    background-color: #00a69c !important;
}
</style>
