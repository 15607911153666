<template>
  <div>
   
    
   
 <b-alert show>Preencha os dados do Cartão de Crédito abaixo e clique no botão pagar</b-alert>
    <b-card
      class="mt-5"
      header="Cartão Crédito"
      header-bg-variant="danger"
      header-text-variant="light"
      
    >
      <b-form class="form was-validated">
        <b-form-group
          id="input-group-1"
          label="Nome no Cartão"
          label-for="input-1"
          description=""
        >
          <b-form-input
            id="input-1"
            v-model="form.cnomecartao"
            type="text"
            placeholder="Digite Nome no Cartão"
            required
          ></b-form-input>
        </b-form-group>

        <b-form-group
          id="input-group-2"
          label="Número do Cartão"
          label-for="input-2"
          description=""
        >

        <input id="input-2"
                v-maska="'#### #### #### ####'"
                class="form-control"
            v-model="form.cnumerocartao"
                required
              />


        </b-form-group>

        <b-form-group
          id="input-group-3"
          label="Codigo de Segurança (CVV)"
          label-for="input-3"
          description=""
        >
          <b-form-input
            id="input-3"
            v-model="form.cvv"
            type="text"
            placeholder=""
            required
          ></b-form-input>
        </b-form-group>

<b-form-group
          id="input-group-6"
          label="Validade Mês"
          label-for="select-6"
          description=""
        >
          <b-form-select
            id="select-6"
            v-model="form.cmes"
            :options="optionsmes"
          ></b-form-select>
        </b-form-group>

        <b-form-group
          id="input-group-7"
          label="Validade Ano"
          label-for="select-7"
          description=""
         
        >
          <b-form-select
            id="select-7"
            v-model="form.cano"
            :options="optionsano"
             required
          ></b-form-select>
          
        </b-form-group>



       <b-form-group
        id="input-group-8"
        label="Numero Telefone ou Celular:"
        label-for="input-8"
        description=""
      >
      <input id="input-8"
                v-maska="['(##)####-####', '(##)#####-####']"
                class="form-control"
                v-model="form.cfone"
                required
              />
      </b-form-group>
 <span >Valor da Compra </span><br>
 <span class="text-danger"> {{ form.cvalor | currency("R$") }} </span>
        <b-form-group
          id="input-group-5"
          label="Parcelar em"
          label-for="select-5"
          description=""
        >
          <b-form-select
            id="select-5"
            v-model="form.cparcelarate"
            :options="options"
            @change="mostrarJuros"
          ></b-form-select>
           <b-alert variant="success" class="m-2" :show="mostrarparcela">
          Valor da Parcela: <br> {{valorparcmaisjuros | currency("R$") }} <br> (juros {{perjuros }} %) 
          <br>
          Total a Pagar: <br> {{ LiquidoMaisJuros | currency("R$") }} 
          </b-alert>
        </b-form-group>

        
      


      </b-form>
     
    </b-card>

   <button class="mb-2" v-on:click="paymethod">Pagar</button>
    <b-spinner v-show="loading" variant="success"></b-spinner>
    <div ref="paypal"></div>

    <div v-if="paidFor">
      <b-alert variant="success" show
        >Pagamento efetuado com sucesso ! Transação Asaas:{{
          transacao
        }}
        Agradecemos sua compra ! Verifique seu email</b-alert>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import swal from 'sweetalert'
export default {
  data: function () {
    return {
      loading: false,
      mensagem:'',
      loaded: false,
      paidFor: false,
      success: false,
      pedidoid:0,
      mode: "",
      transacao: "",
      token: "",
      form: {
        cnomecartao: "",
        cnumerocartao: "",
        cvv: "",
        cvalor: 0.0,
        cparcelarate: "1",
        cmes: "01",
        cano: "",
        cfone:""
       
      },
      options: [],

      optionsmes: [
        { value: "01", text: "01" },
        { value: "02", text: "02" },
        { value: "03", text: "03" },
        { value: "04", text: "04" },
        { value: "05", text: "05" },
        { value: "06", text: "06" },
        { value: "07", text: "07" },
        { value: "08", text: "08" },
        { value: "09", text: "09" },
        { value: "10", text: "10" },
        { value: "11", text: "11" },
        { value: "12", text: "12" },
      ],
      optionsano: [],
      jurosparc:[],
      valorparc:0.00,
      valorparcmaisjuros:0.00,
      perjuros:0.00,
      LiquidoMaisJuros:0.00,
      TotalJuros:0.00,
      mostrarparcela:false      
    };
  },

  methods: {
     formatarValor(value) {
      return parseFloat(value).toFixed(2);
    },
  
   mostrarJuros() {
      console.log('mostrarjuros')
      const selectedParcelas = this.form.cparcelarate;
      console.log('parcela '+selectedParcelas)
      console.log('tamanho '+this.jurosparc.length)
      console.log('jurosparc '+this.jurosparc)

      //const jurosObj = this.jurosparc.find((obj) => obj.nrparc === selectedParcelas.toString());
      
      for (let i = 0; i < this.jurosparc.length; i++) {
        const obj = this.jurosparc[i];
        console.log('Comparando:', obj.nrparc, selectedParcelas.toString());
        if (obj.nrparc === selectedParcelas.toString()) {
          this.perjuros = obj.juros;
          console.log('juros '+obj.juros)
          this.valorparc = (this.Liquido / selectedParcelas).toFixed(2);
          console.log('valor parc'+this.valorparc)
          this.valorparcmaisjuros = (parseFloat(this.valorparc) + (parseFloat(this.valorparc) * this.perjuros/100)).toFixed(2);
          console.log('valorparc + juros '+this.valorparcmaisjuros)
          this.TotalJuros = this.Liquido * this.perjuros / 100;
          console.log('total juros '+this.TotalJuros)
          this.LiquidoMaisJuros = (this.Liquido + this.TotalJuros).toFixed(2);
          console.log('liquido + juros '+this.LiquidoMaisJuros)
          this.mostrarparcela = true
          break;
        }
      }
      
     
   }, 
   getFoneCartao(id) {
            this.loading = true;
            const url = process.env.VUE_APP_BACKEND + "ajax_get_fonecartao.php?c="+id
            fetch(url)
                .then((response) => {
                if (response.ok) {
                
                    return response.json();
                }
                })
                .then((data) => {
                    this.form.cfone = data.fonecartao;
                this.loading = false;
                })
                .catch(function (error) {
                     this.error = error;
                    console.log(error);
                });
    },   
    getJurosParc() {
            this.loading = true;
            const url = process.env.VUE_APP_BACKEND + "ajax_get_juros_parc.php"
            fetch(url)
                .then((response) => {
                if (response.ok) {
                
                    return response.json();
                }
                })
                .then((data) => {
                    this.jurosparc = data;
                this.loading = false;
                })
                .catch(function (error) {
                     this.error = error;
                    console.log(error);
                });
    },   
    paymethod() {

      if (this.form.cnomecartao == "") {
        swal("Aviso", "Nome no Cartao deve ser preenchido !", "error");
        return false;
      }

      if (this.form.cnumerocartao == "") {
        swal("Aviso", "Numero do Cartao deve ser preenchido !", "error");
        return false;
      }
      if (this.form.cvv == "") {
        swal("Aviso", "Codigo de Segurança CVV deve ser preenchido !", "error");
        return false;
      }
      if (this.form.cfone == "") {
        swal("Aviso", "Fone deve ser preenchido !", "error");
        return false;
      }

      this.loading = true;
      const url = process.env.VUE_APP_BACKEND + "ajax_get_clientid_paypal.php";
      fetch(url)
        .then((response) => {
          if (response.ok) {
            return response.json();
          }
        })
        .then((data) => {
         this.mode = data.mode;
          
          this.inserirPedido(1);
        })
        .catch(function (error) {
          this.error = error;
          this.loading = false;
          swal("Erro", error, "error");

        });
    },

    inserirPedido(statusped) {


      const url = process.env.VUE_APP_BACKEND +'AprovePaypal.php'
      let dados = "clientid="+this.ClientId
          dados += '&gateway=1'
          if (statusped == 1) {
             dados += '&transacao='
          } else {
            dados += '&transacao='+this.transacao
          }
          dados += '&total='+this.Total
          dados += '&cupomid='+this.CupomId
          dados += '&vlrcupom='+this.VlrCupom
          dados += '&vlrliquido='+this.Liquido
          dados += '&qtditens='+this.QtdCarrinho
          if (statusped == 2) {
            dados += '&pdid='+this.pedidoid
          }
          let extra = this.VlrCupom + this.TotalDesc
          if (extra > 0) {
                extra = extra * -1
                dados += '&extraAmount='+extra
          }
          for (let i = 0; i < this.itensCarrinho.length; i++) {
              let item = this.itensCarrinho[i]
              let indice = i + 1
              dados += '&itemId'+indice+'='+item.id
              dados += '&itemDescription'+indice+'='+item.title
              dados += '&itemAmount'+indice+'='+item.price
              dados += '&itemQuantity'+indice+'='+item.quantity
          }
          fetch(url,{
               method: "POST",
               headers: { "Content-Type":"application/x-www-form-urlencoded" },
               body: dados
          })
          .then((response) => {
            if (response.ok) {
                                
              return response.json();
            }
          })
          .then((json) => {
             if (json.status == 'sucesso') {
                 if (statusped == 1) {
                    this.$store.commit('setPdId',json.id);
                    this.pedidoid = json.id;
                    console.log('Inseriu Pedido '+json.id);
                    this.pagarAsaas(json.id);
                 } else {
                    this.loading = false;
                    this.paidFor = true;

                 }
             } else {
                this.loading = false;
                swal("Erro", json.message, "error");
             }
           })
           .catch(function (error) {
               console.log(error);
               this.loading = false;
               swal("Erro", error, "error");
           })


    },

    pagarAsaas(pedido) {
      const url = process.env.VUE_APP_BACKEND +'pagarAsaas.php'
      let dados = "pdid="+pedido
      dados += "&cmes="+this.form.cmes
      dados += "&cano="+this.form.cano
      dados += "&cnumerocartao="+this.form.cnumerocartao
      dados += "&cnomecartao="+this.form.cnomecartao
      dados += "&cvv="+this.form.cvv
      dados += "&cparcelas="+this.form.cparcelarate
      dados += "&fonecartao="+this.form.cfone
      dados += "&mode="+this.mode     
      fetch(url,{
               method: "POST",
               headers: { "Content-Type":"application/x-www-form-urlencoded" },
               body: dados
          })
          .then((response) => {
            if (response.ok) {
                                
              return response.json();
            }
          })
          .then((json) => {
             if (json.status == 'sucesso') {
                 console.log('Cartao pago ');
                 this.transacao = json.message;
                 this.inserirPedido(2);
             } else {
                this.loading = false;
                swal("Erro", json.message, "error");
             }
           })
           .catch(function (error) {
               this.loading = false;
               swal("Erro", error, "error");
               console.log(error);
           })


    }
  }, // fim methods
  computed: {
    ...mapGetters({
      itensCarrinho: "getCartItems",
      Total: "getTotalCarrinho",
      TotalDesc: "getDescontoCarrinho",
      QtdCarrinho: "getQtdCarrinho",
      CupomId: "getCupomId",
      PerCupom: "getPerCupom",
      ClientId: "getClientId",
      emailpaypal: "getEmailPaypal",
      QtdParc: "getQtdParc"
    }),
    VlrCupom() {
      if (this.PerCupom < 0) {
        return 0;
      } else {
        return ((this.Total - this.TotalDesc) * this.PerCupom) / 100;
      }
    },
    ComDesc() {
      return this.Total - this.TotalDesc;
    },
    Liquido() {
      return this.Total - this.TotalDesc - this.VlrCupom;
    },
    
  },
  created() {
    const dataAtual = new Date();
    const anoAtual = dataAtual.getFullYear();
    const anofim = anoAtual + 10;
    this.form.cmes = String(dataAtual.getMonth() + 1).padStart(2, '0');
    for (let i = anoAtual; i <= anofim; i++) {
      this.optionsano.push({ value: i, text: i });
      console.log(i);
    }
    this.form.cano = dataAtual.getFullYear();

    

  },
  mounted() {
    this.form.cvalor = this.Liquido;
    console.log('getfonecartao com clientid '+this.ClientId)
    this.getFoneCartao(this.ClientId);
    this.getJurosParc();
    console.log('jurosparc'+this.jurosparc)
    console.log('Qtd Parcelas:'+this.QtdParc)
    // Preenchendo as opções
    for (var i = 1; i <= this.QtdParc; i++) {
      var opt = {
        value: i.toString(),
        text: i.toString() + " x"
      };
      console.log(opt)
      this.options.push(opt);
    }

  }
};
</script>

<style scoped></style>
