<template>
<b-card
    header="Resumo do Pedido"
    header-tag="header"
    footer-tag="footer" 
    bg-variant="light"
>
<b-row no-gutters  v-for="item in this.itensCarrinho" :key="item.id">
      <b-col md="6">
        {{item.quantity}} {{ item.title }}
      </b-col>
      <b-col md="6">
       {{ item.price | currency("R$") }}
      </b-col>
</b-row> 

<hr>

<b-row no-gutters >
  <b-col md="6">
    Total      
  </b-col>
  <b-col md="6">
    {{ Total | currency("R$") }} 
  </b-col>
</b-row>

<hr>

<b-row no-gutters  v-if="TotalDesc > 0">
  <b-col md="6">
    <span class="text-danger">Descontos </span>     
  </b-col>
  <b-col md="6">
    <span class="text-danger"> {{ TotalDesc | currency("R$") }} </span>
  </b-col>
</b-row>
<b-row v-if="VlrCupom > 0">
  <b-col md="6">
    <span class="text-danger">Cupom </span>
  </b-col>
  <b-col md="6">
    <span class="text-danger"> {{ VlrCupom | currency("R$") }} </span>
  </b-col>
</b-row>

<hr>

<b-row>
  <b-col md="6">
    <h5 class="card-title">  Total a Pagar </h5>
  </b-col>
  <b-col md="6">
     <h5 class="card-title">{{ Liquido | currency("R$") }}</h5>
  </b-col>
</b-row>
<b-row>
  <b-form-group label="Forma de Pagamento" v-slot="{ ariaDescribedby }">
      <b-form-radio v-model="formapag" :aria-describedby="ariaDescribedby" name="forma-pag" value="P" >Cartão</b-form-radio>
      <b-form-radio v-model="formapag" :aria-describedby="ariaDescribedby" name="forma-pag" value="B" >Boleto/Pix</b-form-radio>
    </b-form-group>
</b-row>
<template #footer>
<div class="text-center">
<act-f2b-botao v-show="emailvalidado && formapag == 'B' "></act-f2b-botao>
<act-paypal-botao v-if="emailvalidado && formapag == 'P' "> </act-paypal-botao>

<b-spinner v-show="loading" variant="success" ></b-spinner>
</div>
<div class="text-center mt-2">
</div>
  <b-modal id="modal-center" centered title="Alerta">
    <p class="my-4">{{msgmodal}}</p>
  </b-modal>
</template>
</b-card>
</template>

<script>
import { mapGetters } from "vuex"
import ActPaypalBotao from "./ActPaypalBotao.vue"
import ActF2bBotao from "./ActF2bBotao.vue"

export default {
        components: {
          ActPaypalBotao,
          ActF2bBotao
        },
        data() {
          return {
              code:'',
              json:'',
              loading:false,
              link:'',
              msgmodal:'',
              formapag: '',
              emailvalidado: false
          }
        },
        computed: {
        ...mapGetters({
        itensCarrinho: "getCartItems",
        Total:'getTotalCarrinho',
        TotalDesc:'getDescontoCarrinho',
        QtdCarrinho:'getQtdCarrinho',
        PerCupom:'getPerCupom',
        Logado:'getLogado',
        ClientId:'getClientId',
        PedId:'getPdId',
        ParceiroPed:'getParceiroPed',
        CupomId:'getCupomId'

        }),
        VlrCupom() {
            if (this.PerCupom < 0) {
                    return 0
            } else {
                return (this.Total - this.TotalDesc) * this.PerCupom / 100    
            }
        },
        ComDesc() {
        return this.Total - this.TotalDesc
        },
        Liquido() {
            return this.Total - this.TotalDesc - this.VlrCupom
        }
    },
    watch: {
      formapag(newValue) {
        this.valida_email()
        console.log('forma '+newValue)
      }
    },
    methods: {

      removeAcento (text)
      {       
          text = text.toLowerCase();                                                         
          text = text.replace(new RegExp('[ÁÀÂÃ]','gi'), 'a');
          text = text.replace(new RegExp('[ÉÈÊ]','gi'), 'e');
          text = text.replace(new RegExp('[ÍÌÎ]','gi'), 'i');
          text = text.replace(new RegExp('[ÓÒÔÕ]','gi'), 'o');
          text = text.replace(new RegExp('[ÚÙÛ]','gi'), 'u');
          text = text.replace(new RegExp('[Ç]','gi'), 'c');
          return text;                 
      },
      valida_email() {
        this.loading = true;
        const url = process.env.VUE_APP_BACKEND + "ajax_email_validado.php"
        const dataJson = 'cl='+this.ClientId
        fetch(url,{
                    method: "POST",
                    headers: { "Content-Type":"application/x-www-form-urlencoded" },
                    body: dataJson
               })
          .then((response) => {
            if (response.ok) {
              return response.json();
            }
          })
          .then((data) => {
               this.loading = false;
              if (data.status == "sucesso") {
                  this.emailvalidado = true
               // this.enviarcheckout()

              } else {
                this.emailvalidado = false;
                this.formapag = ''
                this.msgmodal = data.message
                this.$bvModal.show('modal-center')
 
            }  

           
          })
          .catch(function (error) {
            this.error = error;
          });
        }
    }
    
}
</script>

<style scoped>
.btn-primary {
    color: #fff;
    background-color:#00a69c !important;
    border-color: #00a69c !important;
}
</style>