<template>
   <div>

  <b-button v-b-modal="nomemodal" variant="danger">
         <b-icon icon='percent'></b-icon>
         Ver Desconto
  </b-button>
  
  <b-modal :id="nomemodal" :title="titulo">
    <p class="my-4" v-html="texto">
      {{texto}}
    </p>
  </b-modal>
</div> 
</template>

<script>
export default {
   computed: {
       nomemodal() {
         return 'modal_descprog'+this.id
       }
    },
    props:['texto','id','titulo']
}
</script>

<style scoped>

</style>