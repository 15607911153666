<template>
   
    <b-row>
    
    <b-col v-show="exibecolfiltro">
        <b-row>
         
          <p class="display-4 text-center  mt-3" >Filtrar</p>

          <b-input-group class="mb-3">
            <b-form-input v-model="filtroNome"></b-form-input>
            <b-input-group-append>
            <b-button @click="filtrarNome"><b-icon icon="search"></b-icon></b-button>
          </b-input-group-append>
          </b-input-group>

           <b-spinner v-show="loading" variant="primary" ></b-spinner>
          <act-filtro-check titulo="Categorias" @mudouFiltro='filtrarProduto' ></act-filtro-check>
          <act-filtro-pago titulo="Livre/Pago" @mudouLivrePago="filtrarLivrePago" class="mt-3"></act-filtro-pago>
        </b-row>
    </b-col>
    <b-col cols="10">
    <b-card-group deck>
        <b-row>   
          <p class="display-4  text-center mt-3" >Nossos Produtos <b-button  v-b-tooltip.hover title="Exibir Filtro" variant="outline-primary" @click="exibecolfiltro = !exibecolfiltro"><b-icon icon="filter-square"></b-icon></b-button></p>
            <b-col sm="12" md="4" v-for="prod in produtos" v-bind:key="prod.id" >         
             <act-single-prod 
                v-bind:key="prod.id"
                v-bind:foto="prod.image"
                v-bind:prod="prod"
              
                
                > 
        </act-single-prod>
            </b-col>

       </b-row>
            
    </b-card-group>
    </b-col>        
   </b-row>  
      
   
  
</template>

<script>
import ActSingleProd from "../components/ActSingleProd.vue"
import ActFiltroCheck from "../components/ActFiltroCheck.vue"
import ActFiltroPago from "../components/ActFiltroPago.vue"
import { mapGetters } from "vuex";
export default {
    components: {
        ActSingleProd,
        ActFiltroCheck,
        ActFiltroPago

    },
    data() {
        return {
            produtos: [],
            loading:false,
            erro:'',
            filtro:'',
            filtroPago:'',
            filtroNome:'',
            exibecolfiltro:false,
            show:false
        }

    },
    computed: {

        ...mapGetters({
          itensCarrinho:'getCartItems'
          
          }),
           isMobile: function () {
            return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
          }

    },
    methods: {
       getProdutos() {
      
      this.loading = true;
      const url = process.env.VUE_APP_BACKEND + "ajax_get_produtos.php?f="+this.filtro+'&p='+this.filtroPago+'&n='+this.filtroNome
      fetch(url)
        .then((response) => {
          if (response.ok) {
            return response.json();
          }
        })
        .then((data) => {
          this.produtos = data;
          this.loading = false;
        })
        .catch(function (error) {
          this.error = error;
        });
      },
       filtrarProduto(valor) {
        this.filtro = valor
        this.getProdutos()
      },
      filtrarLivrePago(valor) {
        this.filtroPago = valor
        this.getProdutos()
      },
      filtrarNome() {
        this.getProdutos()
      }

    },
    mounted() {
        console.log('created')
        this.getProdutos();
        if (!this.isMobile) {
          this.exibecolfiltro = true;
        }
    }
    
   
}
</script>

<style scoped>

</style>