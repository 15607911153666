import { render, staticRenderFns } from "./ActQuemSomos.vue?vue&type=template&id=0f7a59f4&scoped=true&"
import script from "./ActQuemSomos.vue?vue&type=script&lang=js&"
export * from "./ActQuemSomos.vue?vue&type=script&lang=js&"
import style0 from "./ActQuemSomos.vue?vue&type=style&index=0&id=0f7a59f4&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0f7a59f4",
  null
  
)

export default component.exports